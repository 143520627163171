import _stream from "stream";
import _util from "util";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var Stream = _stream.Stream;
var util = _util;
exports = DelayedStream;

function DelayedStream() {
  (this || _global).source = null;
  (this || _global).dataSize = 0;
  (this || _global).maxDataSize = 1024 * 1024;
  (this || _global).pauseStream = true;
  (this || _global)._maxDataSizeExceeded = false;
  (this || _global)._released = false;
  (this || _global)._bufferedEvents = [];
}

util.inherits(DelayedStream, Stream);

DelayedStream.create = function (source, options) {
  var delayedStream = new (this || _global)();
  options = options || {};

  for (var option in options) {
    delayedStream[option] = options[option];
  }

  delayedStream.source = source;
  var realEmit = source.emit;

  source.emit = function () {
    delayedStream._handleEmit(arguments);

    return realEmit.apply(source, arguments);
  };

  source.on("error", function () {});

  if (delayedStream.pauseStream) {
    source.pause();
  }

  return delayedStream;
};

Object.defineProperty(DelayedStream.prototype, "readable", {
  configurable: true,
  enumerable: true,
  get: function () {
    return (this || _global).source.readable;
  }
});

DelayedStream.prototype.setEncoding = function () {
  return (this || _global).source.setEncoding.apply((this || _global).source, arguments);
};

DelayedStream.prototype.resume = function () {
  if (!(this || _global)._released) {
    this.release();
  }

  (this || _global).source.resume();
};

DelayedStream.prototype.pause = function () {
  (this || _global).source.pause();
};

DelayedStream.prototype.release = function () {
  (this || _global)._released = true;

  (this || _global)._bufferedEvents.forEach(function (args) {
    (this || _global).emit.apply(this || _global, args);
  }.bind(this || _global));

  (this || _global)._bufferedEvents = [];
};

DelayedStream.prototype.pipe = function () {
  var r = Stream.prototype.pipe.apply(this || _global, arguments);
  this.resume();
  return r;
};

DelayedStream.prototype._handleEmit = function (args) {
  if ((this || _global)._released) {
    (this || _global).emit.apply(this || _global, args);

    return;
  }

  if (args[0] === "data") {
    (this || _global).dataSize += args[1].length;

    this._checkIfMaxDataSizeExceeded();
  }

  (this || _global)._bufferedEvents.push(args);
};

DelayedStream.prototype._checkIfMaxDataSizeExceeded = function () {
  if ((this || _global)._maxDataSizeExceeded) {
    return;
  }

  if ((this || _global).dataSize <= (this || _global).maxDataSize) {
    return;
  }

  (this || _global)._maxDataSizeExceeded = true;
  var message = "DelayedStream#maxDataSize of " + (this || _global).maxDataSize + " bytes exceeded.";
  this.emit("error", new Error(message));
};

export default exports;